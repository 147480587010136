<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <!--              <a-form-item label="用户id,外键{zb_user.id}" prop="userId">
                <a-input v-model="queryParam.userId" placeholder="请输入用户id,外键{zb_user.id}" allow-clear/>
              </a-form-item>-->
              <a-form-item :label="$t('服务管理.用户咨询管理.类型')" prop="type">
                <a-select v-model="queryParam.type">
                  <a-select-option :value="0">{{ $t('服务管理.用户咨询管理.贷款咨询') }}</a-select-option>
                  <a-select-option :value="1">{{ $t('服务管理.用户咨询管理.多项目咨询') }}</a-select-option>
                  <a-select-option :value="2">{{ $t('服务管理.用户咨询管理.联系平台') }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="姓名" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入姓名" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="电话" prop="phone">
                  <a-input v-model="queryParam.phone" placeholder="请输入电话" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="邮箱" prop="email">
                  <a-input v-model="queryParam.email" placeholder="请输入邮箱" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['consult:consult:add']">
          <a-icon type="plus" />新增
        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['consult:consult:edit']">
          <a-icon type="edit" />修改
        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['consult:consult:remove']">
          <a-icon type="delete" />{{ $t('通用.删除') }}
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['consult:consult:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!--          <a-divider type="vertical" v-hasPermi="['consult:consult:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['consult:consult:edit']">
            <a-icon type="edit" />修改
          </a>-->
          <a-divider type="vertical" v-hasPermi="['consult:consult:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['consult:consult:remove']">
            <a-icon type="delete" />{{ $t('通用.删除') }}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageConsult, listConsult, delConsult } from '@/api/consult/consult'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Consult',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        type: null,
        name: null,
        phone: null,
        email: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        /*{
          title: '用户id,外键{zb_user.id}',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (e) {
            if (e === 0) {
              return '贷款咨询'
            } else if (e === 1) {
              return '多项目咨询'
            } else if (e === 2) {
              return '联系平台'
            }
          },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '电话',
          dataIndex: 'phone',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询咨询列表 */
    getList() {
      this.loading = true
      pageConsult(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        type: undefined,
        name: undefined,
        phone: undefined,
        email: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('服务管理.服务内容管理.确认删除所选中数据'),
        content: this.$t('服务管理.服务内容管理.当前选中编号为') + ids,
        okText: this.$t('通用.确认'),
        cancelText: this.$t('通用.取消'),
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delConsult(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'consult/consult/export',
            {
              ...that.queryParam,
            },
            `咨询_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
     updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
            {
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (e) {
            if (e === 0) {
              return '贷款咨询'
            } else if (e === 1) {
              return '多项目咨询'
            } else if (e === 2) {
              return '联系平台'
            }
          },
        },
        {
          title: 'Name',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Phome',
          dataIndex: 'phone',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'CreateTime',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
        ],
        'zh-CN': [
               {
          title: '编号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (e) {
            if (e === 0) {
              return '贷款咨询'
            } else if (e === 1) {
              return '多项目咨询'
            } else if (e === 2) {
              return '联系平台'
            }
          },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '电话',
          dataIndex: 'phone',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
